const cartCreditDefaultUrl = require('../assets/images/icon-credit-card-blue@3x.png');
const cartCreditGreenUrl = require('../assets/images/icon-credit-card-blue@3x.png');

const cartClientDefaultUrl = require('../assets/images/icon-client-card-blue@3x.png');
const cartClientGreenUrl = require('../assets/images/icon-client-card-blue@3x.png');

const indexProductScanDefaultUrl = require('../assets/images/index-product-scan-blue.png');
const indexProductScanGreenUrl = require('../assets/images/index-product-scan-blue.png');

const flagUS = require('../assets/images/icon-flag-united-states.png');
const flagGermany = require('../assets/images/icon-flag-germany.png');
const flagDenmark = require('../assets/images/icon-flag-denmark.png');
const flagUK = require('../assets/images/icon-flag-united-kingdom.png');

export default {
  iCartCredit: {
    defaultUrl: cartCreditDefaultUrl,
    greenUrl: cartCreditGreenUrl,
  },
  iCartClient: {
    defaultUrl: cartClientDefaultUrl,
    greenUrl: cartClientGreenUrl,
  },
  iIndexProductScan: {
    defaultUrl: indexProductScanDefaultUrl,
    greenUrl: indexProductScanGreenUrl,
  },
  flags: {
    unitedStates: flagUS,
    germany: flagGermany,
    denmark: flagDenmark,
    unitedKingdom: flagUK,
  },
};
